import React from 'react';
import { Info } from 'lucide-react';
import { Contact } from '../types';

interface ChatHeaderProps {
  contact: Contact;
  onToggleProfile: () => void;
}

const ChatHeader: React.FC<ChatHeaderProps> = ({
  contact,
  onToggleProfile,
}) => {
  return (
    <div className="p-4 flex items-center justify-between w-full">
      <div className="flex items-center space-x-4">
        <img
          src={contact.avatar}
          alt={contact.name}
          className="w-10 h-10 rounded-full object-cover"
          onError={(e) => {
            e.currentTarget.src = "https://upload.wikimedia.org/wikipedia/commons/a/ac/Default_pfp.jpg";
          }}
        />
        <div>
          <a href={`/crm/${contact.id}`} className="text-lg font-medium text-gray-900 hover:underline">{contact.name}</a>
        </div>
      </div>
      
      {/* <div className="flex items-center space-x-3">
        <button
          onClick={onToggleProfile}
          className="p-2 hover:bg-gray-100 rounded-full"
          title="View Profile"
        >
          <Info className="h-5 w-5 text-gray-600" />
        </button>
      </div> */}
    </div>
  );
};

export default ChatHeader;